import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
//
import { PATH_DASHBOARD } from './paths';

// ----------------------------------------------------------------------

const DashboardRoutes = {
  path: PATH_DASHBOARD.root,
  layout: DashboardLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DASHBOARD.general.dashboard,
      component: lazy(() => import('../views/Dashboard'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.trade,
      component: lazy(() => import('../views/Trade'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.wallet,
      component: lazy(() => import('../views/PageTwo'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.rules,
      component: lazy(() => import('../views/PageThree'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.history,
      component: lazy(() => import('../views/TransactionHistory'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.coinspot,
      component: lazy(() => import('../views/Coinspot'))
    },

    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default DashboardRoutes;
