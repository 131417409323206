import { createSlice } from '@reduxjs/toolkit';
import Auth from '@aws-amplify/auth'
import Cache from '@aws-amplify/cache'
import aws4 from "aws4"
import { API_ENDPOINT, API_REGION, API_PROTOCOL, API_STAGE } from './auth';


const initialState = {
    wallets: [],
    balance: 0,
    currencies: [],
    rules: [],
    transactionHistory: [],
    fulluser: null,
    coinspotBalance:null
};

const slice = createSlice({
    name: 'api',
    initialState,
    reducers: {
        // LOGIN
        getuser(state, action) {
            state.fulluser = {profile:action.payload.data.profile}
            console.log("WALLETS", state.fulluser)
        },
        getwallets(state, action) {
            state.wallets = action.payload.data.data
            console.log("WALLETS", action.payload)
        },
        getbalance(state, action) {
            state.balance = action.payload.data.balance
            console.log("BALANCE", state.balance)
        },
        getcurrencies(state, action) {
            state.currencies = action.payload.data.currencies
            console.log("CURRENCIES", state.currencies)
        },
        getrules(state, action) {
            state.rules = action.payload.data.rules
        },
        gethistory(state, action) {
            state.history = action.payload.data.history
            console.log("HISTORY", state.history)
        },
        buycoins(state, action) {
            //
        },
        sellcoins(state, action) {
            //
        },
        connectcoinspot(state, action) {

        },
        getcoinspotbalance(state, action) {
            state.coinspotBalance = action.payload.data
        }
    }
});

// Reducer
export default slice.reducer;

export function getData(path, queryString = {}, signRequest = false) {

    return function (dispatch) {
        let action = path
        path = "/" + path
        queryString = buildQueryString(queryString)

        return new Promise(async (resolve, reject) => {

            let response = await _getData(path, queryString, signRequest)
            if (response) {
                dispatch(slice.actions[action]({ data: response }))
                resolve(response)
            } else {
                dispatch(slice.actions[action]({ data: null }))
                resolve(false)
            }
        })

    }

}

async function _getData(path, queryString = "", signRequest = false) {
    let promise = signRequest ?
        new Promise(async (resolve, reject) => {

            var options = {
                method: "GET",
                service: "execute-api",
                region: API_REGION,
                host: API_ENDPOINT,
                path: API_STAGE + path + queryString,
                url: API_PROTOCOL + "//" + API_ENDPOINT + API_STAGE + path + queryString,
            }
            const credentials = await Auth.currentCredentials()
            const { accessKeyId, secretAccessKey, sessionToken } = credentials;
            const request = aws4.sign(options, {
                accessKeyId,
                secretAccessKey,
                sessionToken
            });
            delete request.headers.Host;
            let res
            //console.log(options)
            try {
                res = await fetch(options.url, {
                    headers: request.headers
                })
            } catch (e) {
                console.log("Error fetching, ", e)
            }

            if (!res || !res.ok) {
                resolve(false);
            } else {
                console.log("res", res)
                //const data = await res.text()
                let data = await res.text()
                data = JSON.parse(data)
                if (data && !data.error) {
                    console.log(data)
                    resolve(data);
                } else {
                    if (data.error) alert(data.message || "There was an error.")
                    resolve(false);
                }
            }
        })

        :

        new Promise(async (resolve, reject) => {
            const res = await fetch(API_PROTOCOL + "//" + API_ENDPOINT + API_STAGE + path + queryString)
            if (!res || !res.ok) {
                resolve(false);
            } else {
                console.log("res", res)
                let data
                try {
                    data = await res.json()
                    if (data) {
                        resolve(data);
                    } else {
                        resolve(false);
                    }
                } catch (e) {
                    console.log("Error", e)
                    resolve(false)
                }
            }
        })

    return promise
}

export function postData(path, data, signRequest = false) {
    return function (dispatch) {
        let action = path
        path = "/" + path
        return new Promise(async (resolve, reject) => {
            let response = await _postData(path, data, signRequest)
            if (response) {
                dispatch(slice.actions[action]({ data: response }))
                resolve(response)
            } else {
                resolve(false)
            }
        })

    }
}

async function _postData(path, data, signRequest = false) {
    let promise = signRequest ?
        new Promise(async (resolve, reject) => {

            var options = {
                method: "POST",
                service: "execute-api",
                region: API_REGION,
                host: API_ENDPOINT,
                path: API_STAGE + path,
                url: API_PROTOCOL + "//" + API_ENDPOINT + API_STAGE + path,
                body: JSON.stringify(data)
            }
            const credentials = await Auth.currentCredentials();
            const { accessKeyId, secretAccessKey, sessionToken } = credentials;
            const request = aws4.sign(options, {
                accessKeyId,
                secretAccessKey,
                sessionToken
            });
            delete request.headers.Host;
            const res = await fetch(options.url, {
                method: "POST",
                headers: request.headers,
                body: JSON.stringify(data)
            })
            if (!res || !res.ok) {
                resolve(false);
            } else {
                let data = await res.text()
                data = JSON.parse(data)
                console.log("data", data)
                if (data && !data.error) {
                    resolve(data);
                } else {
                    if (data.error) alert(data.message || "There was an error.")
                    resolve(false);
                }
            }
        })

        :

        new Promise(async (resolve, reject) => {

            const res = await fetch(API_PROTOCOL + "//" + API_ENDPOINT + API_STAGE + path,
                {
                    method: 'post',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(data)
                }
            )
            if (!res || !res.ok) {
                console.log("error with getData", res)
                resolve(false);
            } else {
                console.log("res", res)

                const data = await res.json()
                if (data) {
                    resolve(data);
                } else {
                    resolve(false);
                }
            }

        })

    return promise
}

function buildQueryString(obj) {
    if (!obj) return ""
    let qs = "?"
    for (var k in obj) {
        if (qs != "?") qs += "&"
        qs += (k + "=" + obj[k])
    }
    return qs
}