import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import settingsReducer from './slices/settings';
import authReducer from './slices/auth';
import apiReducer from './slices/api';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['settings']
};

const authPersistConfig = {
  key: 'auth',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['isAuthenticated']
};

const rootReducer = combineReducers({
  settings: settingsReducer,
  api:apiReducer,
  auth: persistReducer(authPersistConfig, authReducer)

});

export { rootPersistConfig, rootReducer };
