import { Icon } from '@iconify/react';
import { useRef, useState, useEffect } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink, useHistory } from 'react-router-dom';
// material
import { alpha } from '@material-ui/core/styles';
import {
  Box,
  Avatar,
  Button,
  Divider,
  MenuItem,
  Typography
} from '@material-ui/core';
// components
import { MIconButton } from '../../components/@material-extend';
import MenuPopover from '../../components/MenuPopover';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: homeFill,
    linkTo: '/'
  },
  {
    label: 'Profile',
    icon: personFill,
    linkTo: '#'
  },
  {
    label: 'Settings',
    icon: settings2Fill,
    linkTo: '#'
  }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [profile, setProfile] = useState(null);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const auth = useAuth()
  const history = useHistory()

  useEffect(() => {
    if (!mounted) {


      const ga = window.gapi && window.gapi.auth2 ?
        window.gapi.auth2.getAuthInstance() :
        null;

      if (ga) {

        let u = ga.currentUser.get()
        let p = u.getBasicProfile()
        if (p) {
          setProfile({
            name: p.getName(),
            avatar: p.getImageUrl(),
            email: p.getEmail()
          })

          setMounted(true)
        }

      }

    }
  })

  function _logout() {
    auth.logout(() => {
      history.push("/")
    })

  }

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar
          alt="My Avatar"
          src={profile ? profile.avatar : "/static/mock-images/avatars/avatar_default.jpg"}
        />
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {profile ? profile.name : ""}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {profile ? profile.email : ""}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={_logout}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
