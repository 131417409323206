// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/crypto';

// ----------------------------------------------------------------------

export const PATH_HOME = {
  components: '/components',
  cloud: 'https://www.sketch.com/s/0fa4699d-a3ff-4cd5-a3a7-d851eb7e17f0',
  purchase: 'https://material-ui.com/store/items/minimal-dashboard/',
  dashboard: ROOTS_DASHBOARD
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    dashboard: path(ROOTS_DASHBOARD, '/dashboard'),
    trade: path(ROOTS_DASHBOARD, '/trade'),
    wallet: path(ROOTS_DASHBOARD, '/wallet'),
    rules: path(ROOTS_DASHBOARD, '/rules'),
    history: path(ROOTS_DASHBOARD, '/history'),
    coinspot: path(ROOTS_DASHBOARD, '/coinspot')
  }
};
