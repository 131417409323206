// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name, dir = "icons/navbar") => (
  <SvgIconStyle
    src={`/static/${dir}/${name}.svg`}
    sx={{ width: 22, height: 22 }}
  />
);

const ICONS = {
  page: getIcon('ic_page'),
  dashboard: getIcon('ic_analytics'),
  ecommerce: getIcon('ic_ecommerce'),
  elements: getIcon('ic_elements'),
  code: getIcon('ic_code', 'icons'),
  list: getIcon('list_unordered', 'icons/editor'),
  sketch: getIcon('ic_sketch', 'icons'),
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    /* subheader: 'general', */
    items: [
      {
        title: 'Dashboard',
        href: PATH_DASHBOARD.general.dashboard,
        icon: ICONS.dashboard
      },
      {
        title: 'Trade',
        href: PATH_DASHBOARD.general.trade,
        icon: ICONS.ecommerce
      },
      /* {
        title: 'Wallet',
        href: PATH_DASHBOARD.general.wallet,
        icon: ICONS.elements
      }, */
      {
        title: 'Rules',
        href: PATH_DASHBOARD.general.rules,
        icon: ICONS.code
      },
      {
        title: 'History',
        href: PATH_DASHBOARD.general.history,
        icon: ICONS.list
      },
      {
        title: 'Coinspot',
        href: PATH_DASHBOARD.general.coinspot,
        icon: ICONS.sketch
      }
    ]
  },

  // APP
  // ----------------------------------------------------------------------
  /* {
    subheader: 'app',
    items: [
      {
        title: 'Drop',
        href: PATH_DASHBOARD.app.root,
        icon: ICONS.dashboard,
        items: [
          {
            title: 'page Four',
            href: PATH_DASHBOARD.app.pageFour
          },
          {
            title: 'Page Five',
            href: PATH_DASHBOARD.app.pageFive
          },
          {
            title: 'Page Six',
            href: PATH_DASHBOARD.app.pageSix
          }
        ]
      }
    ]
  } */
];

export default sidebarConfig;
