import Amplify, { Auth, Hub } from 'aws-amplify'

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { logout, checkUserSession } from 'src/redux/slices/auth';


// ----------------------------------------------------------------------

const isLocal = window.location.href.indexOf("sandbox") > -1


// AWS Amplify
Amplify.configure({
    Auth: {
        region: "ap-southeast-2",
        identityPoolId: "ap-southeast-2:bfdac1cc-1d94-4b8b-920e-e6c7cbcf4a50",
        userPoolId: "ap-southeast-2_qalc4FYZR",
        userPoolWebClientId: "786eup19lgjc92an5diu1inlsa",
        mandatorySignIn: false,
        cookieStorage: {
            domain: isLocal ? "sandbox.xchangesim.com" : "xchangesim.com",
            path: "/",
            expires: 365,
            secure: true
        },
        redirectSignIn: isLocal ? "https://sandbox.xchangesim.com:3000" : "https://xchangesim.com",
        redirectSignOut: isLocal ? "https://sandbox.xchangesim.com:3000" : "https://xchangesim.com"
    }
})

Auth.configure({
    oauth: {
        domain: "xcs2.auth.ap-southeast-2.amazoncognito.com",
        scope: [
            "email",
            "profile",
            "openid"
        ],
        redirectSignIn: isLocal ? "https://sandbox.xchangesim.com:3000" : "https://xchangesim.com",
        redirectSignOut: isLocal ? "https://sandbox.xchangesim.com:3000" : "https://xchangesim.com",
        responseType: "token"
    }
})

Hub.listen('auth', ({ payload: { event, data } }) => {
    switch (event) {
        case 'signIn':
            console.log('sign in', event, data)
            break
        case 'signOut':
            console.log('sign out')
            break
        default:
            console.log("HUB", event)
            break
    }
})




export default function useAuth() {

    const dispatch = useDispatch();
    const { user, profile } = useSelector(
        (state) => state.auth
    )

    return {
        user: user,
        profile: profile,
        logout: (callback) => {
            dispatch(logout()).then(callback)
        },
        checkUser: (callback) => {
            dispatch(checkUserSession()).then(callback)
        },
        /* updateProfile: (profile) => {
            dispatch(updateUserProfile(profile))
        }, */


    };
}
