import { createSlice } from '@reduxjs/toolkit';
import Auth from '@aws-amplify/auth'
import Cache from '@aws-amplify/cache'
import aws4 from "aws4"
import { useSelector } from 'react-redux';


export const API_ENDPOINT = "o18jzj3fw8.execute-api.ap-southeast-2.amazonaws.com"
export const API_PROTOCOL = "https:"
export const API_REGION = "ap-southeast-2"
export const API_STAGE = "/Prod"

const initialState = {
    isLoading: false,
    isAuthenticated: false,
    user: null,
    profile: null

};

const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        // LOGIN
        onSignIn(state, action) {
            state.isAuthenticated = true;
            state.user = action.payload.user;
            console.log("User signed in", state.user)
        },
        onTest(state, action) {
            state.isAuthenticated = true;
            state.user = action.payload.user
        },
        updateUserProfile(state, action) {
            state.isAuthenticated = true;
            state.profile = action.payload.profile
        }
    }
});

// Reducer
export default slice.reducer;


export function checkUserSession() {
    return function (dispatch) {
        dispatch({ type: 'API_LOADING' })
        return new Promise(async (resolve, reject) => {
            Auth.currentAuthenticatedUser()
                .then(async (data) => {
                    console.log("has user", data)
                    dispatch(slice.actions.onSignIn({ user: data }))
                    resolve()
                })
                .catch((err) => {
                    console.log("ERROR", err)
                    dispatch(slice.actions.onSignIn({ user: null }))
                    resolve()
                });
        })

    }

}

export function logout() {
    return function (dispatch) {
        return new Promise(async (resolve, reject) => {
            await Auth.signOut()
            dispatch(slice.actions.onSignIn({ user: null }))
            resolve()
        })
    }
}
